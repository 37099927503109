import * as THREE from 'three';

var camera, scene, renderer,
	geometry, material, mesh;

let cubeSineDriver = 0;
let light;
let smokeTexture;
let smokeMaterial;
let smokeGeo;
let smokeParticles;
let delta;
let clock;
let p = 0;

document.addEventListener("DOMContentLoaded", () => {
	init();
	animate();
})

function init() {
    renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setClearColor(0xffffff, 0);

    scene = new THREE.Scene();
    clock = new THREE.Clock();

    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 1000;
    scene.add(camera);

    geometry = new THREE.BoxGeometry(200, 200, 200);
    material = new THREE.MeshLambertMaterial({ color: 0xffffff, wireframe: false, transparent: true });
    mesh = new THREE.Mesh(geometry, material);

    light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(-1, 0, 1);
    scene.add(light);

    const textureLoader = new THREE.TextureLoader();
    smokeTexture = textureLoader.load('/wp-content/themes/brut-communicatie-tw/assets/img/Smoke-Element.png');
    smokeMaterial = new THREE.MeshLambertMaterial({ 
        color: 0xffffff, 
        map: smokeTexture, 
        transparent: true,
        opacity: 0.5, // Adjust opacity for more intensity
        blending: THREE.AdditiveBlending // Use additive blending for more intense effect
    });

    smokeGeo = new THREE.PlaneGeometry(300, 300);
    smokeParticles = [];

    for (let i = 0; i < 150; i++) { // Increase the number of particles
        let particle = new THREE.Mesh(smokeGeo, smokeMaterial);
        particle.position.set(Math.random() * 1000 - 500, Math.random() * 1000 - 500, Math.random() * 1000 - 500);
        particle.rotation.z = Math.random() * 360;
        scene.add(particle);
        smokeParticles.push(particle);
    }

	let el = document.querySelectorAll('.smoke');

	el.forEach((header) => {
		let container = document.createElement('div');
		container.classList.add('smoke-container');
		container.appendChild(renderer.domElement);
		header.appendChild(container);
	})
}

function animate() {
	delta = clock.getDelta();
	requestAnimationFrame(animate);
	evolveSmoke();
	render();
}

function evolveSmoke() {
	var sp = smokeParticles.length;
	while (sp--) {
		smokeParticles[sp].rotation.z += (delta * 0.2);
	}
}

function render() {
	mesh.rotation.x += 0.005;
	mesh.rotation.y += 0.01;
	cubeSineDriver += .01;
	mesh.position.z = 100 + (Math.sin(cubeSineDriver) * 500);
	renderer.render(scene, camera);
}






