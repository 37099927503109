import gsap from "gsap";

document.addEventListener('DOMContentLoaded', () => {
	let isOpen = false;

	const offCanvas = document.querySelector('#offcanvas-main');
	const toggler = document.querySelector('#offcanvas-toggler');
	const menuItems = document.querySelectorAll('.offcanvas .brut-container > ul > .menu-item');
	const parentMenuItems = document.querySelectorAll('.menu-item-has-children');
	const contactLinks = document.querySelectorAll('.site-header .phone-link, .site-header .email-link');
	const offcanvasSocialIcons = document.querySelectorAll('.offcanvas .social-links a');
	const textLogo = document.querySelectorAll('.site-header .fill-white');
	const vacancyIndicator = document.querySelector('.vacancy-indicator');
	const tl = gsap.timeline();
	
	tl.set(offCanvas, { yPercent: -100, scale: 0.95 });
	
	toggler.addEventListener('click', () => {
		tl.clear();
		
		if (isOpen) {
			tl.to(offCanvas, { yPercent: -100, duration: 1.5, ease: 'bounce.out' });
			tl.to(contactLinks, { opacity: 1, pointerEvents: 'auto', duration: 0.2 });
			tl.to(textLogo, { fill: '#fff', duration: 0.5, ease: 'power2.inOut' }, '-=1.5');
			tl.set(offCanvas, { scale: 0.95 });
		} else {
			tl.set(menuItems, { opacity: 0, x: -20, rotate: '-15deg' });
			tl.set(offcanvasSocialIcons, { opacity: 0, x: -20, rotate: '0deg' });
			tl.to(offCanvas, { yPercent: 0, duration: 1.0, ease: 'expo.out' });
			tl.to(offCanvas, { scale: 1, duration: 1.0, ease: 'expo.out' }, '-=0.5');
			tl.to(contactLinks, { opacity: 0, pointerEvents: 'none', duration: 0.2 }, '-=1.5');
			tl.to(textLogo, { fill: '#000', duration: 0.5, ease: 'power2.inOut' }, '-=1.7');
			tl.to(menuItems, { opacity: 1, x: 0, duration: 0.5, rotate: 0, ease: 'power2.inOut', stagger: 0.1 }, '-=1.5');
			tl.to(offcanvasSocialIcons, { opacity: 1, x: 0, duration: 2.0, rotate: '360deg', ease: 'elastic.inOut', stagger: 0.2 }, '-=1.0');
			if (vacancyIndicator) {
				tl.to(vacancyIndicator, { scale: 1.2, rotate: '-15deg', duration: 0.5, ease: 'power2.inOut' });
				tl.to(vacancyIndicator, { scale: 1, rotate: '0deg', duration: 0.5, ease: 'power2.inOut' });
			}
		}

		isOpen = !isOpen;
		toggler.classList.toggle('active');
		document.body.classList.toggle('open-menu');
		document.body.classList.toggle('overflow-hidden');
	});

	parentMenuItems.forEach((parentMenuItem) => {
		const parentMenuLink = parentMenuItem.querySelector('a');
		const subMenu = parentMenuItem.querySelector('.sub-menu');
		const icon = parentMenuItem.querySelector('i');
		const subMenuItems = subMenu.querySelectorAll('.menu-item');
		let subMenuIsOpen = false;

		parentMenuLink.addEventListener('click', (e) => {
			e.preventDefault();
			const tl = gsap.timeline();
			gsap.to(icon, { rotation: icon.style.transform === 'rotate(180deg)' ? 0 : 180, duration: 0.5, ease: 'power2.inOut' });

			if (subMenuIsOpen) {
				tl.to(subMenuItems, { opacity: 0, y: -20, duration: 0.5, delay: 0.7, ease: 'power2.inOut', stagger: 0.1 });
				tl.to(subMenu, { marginTop: '0', marginBottom: '0', visibility: 'hidden', height: 0, duration: 0.5, delay: 0.2 });
			} else {
				gsap.set(subMenuItems, { opacity: 0, y: -20 });
				tl.to(subMenu, { marginTop: '1rem', marginBottom: '1rem', height: 'auto', visibility: 'visible', duration: 0.5, delay: 0.5, ease: 'power2.inOut' });
				tl.to(subMenuItems, { opacity: 1, y: 0, duration: 0.5, ease: 'power2.inOut', stagger: 0.1 });
			}

			subMenuIsOpen = !subMenuIsOpen;
		});
	});
});
