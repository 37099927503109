import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function calcBottom(parentEl, el) {
	const parentTop = parentEl.getBoundingClientRect().top;
	const elBottom = el.getBoundingClientRect().bottom;
	const diff = elBottom - parentTop;
	return diff + 'px';
}

document.addEventListener('DOMContentLoaded', () => {
	const screenshotsContainer = document.querySelector('section.screenshots');
	const screenshotWrapper = screenshotsContainer?.querySelector('.desktop-screenshot');
	const img = screenshotsContainer?.querySelector('.frame-inner');

	console.log(calcBottom(screenshotWrapper, img));

	if (screenshotsContainer) {
		gsap.set(img, {bottom: calcBottom(screenshotWrapper, img)});
		gsap.to(img, {
			top: 'unset',
			bottom: '9px',
			scrollTrigger: {
				trigger: screenshotsContainer,
				start: "top top",
				end: "+=800",
				scrub: true,
				pin: true,
				anticipatePin: 1,
			}
		});
	}
});
