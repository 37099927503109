import gsap from "gsap";

let matchMedia = gsap.matchMedia();

function processCaseCursor() {
	const findCursor = document.querySelector('.pop-cursor');
	if (!findCursor) return;

	const articles = document.querySelectorAll('.case-card');

	matchMedia.add('(min-width:800px)', () => {
		articles.forEach((article) => {
			const parent = article.closest('section');
			const cursor = parent.querySelector('.pop-cursor');

			article.addEventListener('mouseenter', () => {
				gsap.killTweensOf(cursor);
				cursor.classList.remove('hidden');
				gsap.to(cursor, {
					duration: 0.3,
					scale: 1,
					ease: 'back.out',
				});
			});
		
			article.addEventListener('mouseleave', () => {
				gsap.killTweensOf(cursor);
				gsap.to(cursor, {
					duration: 0.3,
					scale: 0,
					ease: 'power1.out',
					onComplete: () => {
						cursor.classList.add('hidden');
					}
				});
			});
		
			document.addEventListener('mousemove', (e) => {
				gsap.to(cursor, {
					duration: 1.3,
					x: e.clientX - 55,
					y: e.clientY - 55,
					ease: 'back.out'
				});
			});
		});
	});
}

document.addEventListener('DOMContentLoaded', () => {
	processCaseCursor();
});

document.addEventListener('facetwp-loaded', () => {
	if (FWP.loaded) {
		processCaseCursor();
	}
});
