document.addEventListener("scroll", () => {
	let top = window.scrollY;
	
	if ( top < 80 ) {
		document.body.classList.remove('scroll');
	} else if (!document.body.classList.contains('scroll')) {
		document.body.classList.add('scroll');
	}
});

document.addEventListener('DOMContentLoaded', () => {
	const pauseVideoHeader = document.querySelector('.pause-button');

	if (pauseVideoHeader) {
		pauseVideoHeader.addEventListener('click', () => {
			const video = pauseVideoHeader.closest('section').querySelector('video');
			video.pause()
		});
	}
});
